import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import MultitextField from 'core/assets/js/components/FinalFormFields/MultitextField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON, MAX_UPLOAD_FILES, USER_TYPE } from 'core/assets/js/constants';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { uploaderInterviewPath } from 'core/urls';
import {
  ATTACHMENT_TYPE_OPTIONS, TYPE, TYPE_LABEL, VISIBLE_TO_OPTIONS,
} from 'interviews/assets/js/constants';
import { getCustomFieldPath } from 'interviews/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TYPE_OPTIONS = [
  { icon: ICON.TEXTFIELD, label: 'Text Field', type: TYPE.TEXT },
  { icon: ICON.COINS, label: 'Money', type: TYPE.MONEY },
  { icon: ICON.RADIO, label: 'Selection', type: TYPE.SELECT },
  { icon: ICON.ATTACHMENT, label: 'Attachment', type: TYPE.FILE },
  { icon: ICON.CALENDAR_ALT, label: 'Date', type: TYPE.DATE },
  { icon: ICON.CHECKMARK, label: 'Yes/No', type: TYPE.YESNO },
];

const yesNoOptions = [{ value: true, text: 'Yes' }, { value: false, text: 'No' }];

const Question = ({
  fieldIndex,
  fieldName,
  fieldsLength,
  interviewId,
  onChange,
  onRemove,
  onSwap,
  organizationId,
  question,
}) => (
  <div className="interview-builder__question">
    <div className="d-flex align-items-center title-container mb-3">
      {fieldIndex > 0 && (
        <i
          className={`${ICON.ARROW_UP} change-order-up pr-3`}
          onClick={() => onSwap(fieldIndex, fieldIndex - 1)}
        />
      )}
      {fieldIndex < fieldsLength - 1 && (
        <i
          className={`${ICON.ARROW_DOWN} change-order-down pr-3`}
          onClick={() => onSwap(fieldIndex, fieldIndex + 1)}
        />
      )}
      <h4 className="interview-builder__question-title m-0 p-0">
        {`FIELD ${fieldIndex + 1}: `}
        <span>{TYPE_LABEL[question.type]}</span>
      </h4>
      <i
        className={`${ICON.CROSS} interview-builder__question-remove ml-auto`}
        onClick={onRemove}
      />
    </div>
    <div className="clearfix">
      <TextInputField name={`${fieldName}.path`} type="hidden" />
      <TextInputField
        name={`${fieldName}.label`}
        placeholder="Label"
        required
      />
      <TextInputField name={`${fieldName}.description`} placeholder="Optional description" />
      <RadioField
        label="Is this required?"
        name={`${fieldName}.required`}
        onChange={value => onChange(`${fieldName}.required`, value)}
        options={yesNoOptions}
        required
      />
      {question.type === TYPE.MONEY && (
        <RadioField
          label="Add the answer as a rate for the user?"
          name={`${fieldName}.payload.isRate`}
          onChange={value => onChange(`${fieldName}.payload.isRate`, value)}
          options={yesNoOptions}
          required
          sublabel={`Select 'yes' if you want to create a rate on the user's profile with the
            amount provided by the user`}
        />
      )}
      {question.type === TYPE.TEXT && (
        <RadioField
          label="Use multiple lines?"
          name={`${fieldName}.payload.isTextArea`}
          onChange={value => onChange(`${fieldName}.payload.isTextArea`, value)}
          options={yesNoOptions}
          required
        />
      )}
      {question.type === TYPE.SELECT && (
        <>
          <RadioField
            label="Allow selecting multiple answers?"
            name={`${fieldName}.payload.multiple`}
            onChange={value => onChange(`${fieldName}.payload.multiple`, value)}
            options={yesNoOptions}
            required
          />
          <MultitextField
            asOptions
            inputType={INPUT_TYPE.MARKDOWN_INPUT}
            label="Choices"
            maxLength={255}
            name={`${fieldName}.payload.choices`}
            onAutoIncrementUpdate={() => null}
            required
          />
          <RadioField
            label="Has 'Other' option?"
            name={`${fieldName}.payload.has_other`}
            onChange={value => onChange(`${fieldName}.has_other`, value)}
            options={yesNoOptions}
            required
          />
        </>
      )}
      {question.type === TYPE.FILE && (
        <>
          <CheckboxField
            label="Allowed attachment types:"
            name={`${fieldName}.payload.attachment_types`}
            options={ATTACHMENT_TYPE_OPTIONS}
            wrapperClasses="attachment_types--options"
          />
          <RadioField
            label="Upload multiple files"
            name={`${fieldName}.payload.multiple`}
            onChange={value => onChange(`${fieldName}.payload.multiple`, value)}
            options={yesNoOptions}
            required
          />
          <RadioField
            label="Prompt the invitee to set the expiry date"
            name={`${fieldName}.payload.setExpiryDate`}
            onChange={value => onChange(`${fieldName}.payload.setExpiryDate`, value)}
            options={yesNoOptions}
            required
          />
          <FileUploaderField
            acceptAll
            label="Optional file template"
            maxFiles={MAX_UPLOAD_FILES}
            name={`${fieldName}.payload.attachments_dump`}
            path={uploaderInterviewPath(organizationId, interviewId)}
          />
        </>
      )}
      <RadioField
        label="Who should be able to answer this question?"
        name={`${fieldName}.answeredByUserType`}
        onChange={value => onChange(`${fieldName}.answeredByUserType`, value)}
        options={[
          { value: USER_TYPE.PROVIDER, text: 'Provider' },
          { value: USER_TYPE.MANAGER, text: 'Manager' },
        ]}
        required
        sublabel={(
          'If Provider is chosen, then this question will only be answerable by the Provider. If '
            + 'Manager is chosen, then only Managers will be able to answer this question.'
        )}
      />
      <CheckboxField
        label="Answer visible to:"
        name={`${fieldName}.payload.visibleTo`}
        options={VISIBLE_TO_OPTIONS}
        required
        wrapperClasses="visible-to"
      />
    </div>
  </div>
);

Question.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldsLength: PropTypes.number.isRequired,
  interviewId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSwap: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
};

Question.defaultProps = {
  interviewId: null,
};

const InterviewFormConfigure = ({ match: { params: { interviewId } } }) => {
  const { change } = useForm();
  const { submitError, values: { questions } } = useFormState();

  const activeOrg = useSelector(selectActiveOrg);

  const questionsLength = Array.isArray(questions) ? questions.length : 0;
  const hasQuestions = questionsLength > 0;

  return (
    <div className="interview-builder">
      <TextInputField
        label="Name your template"
        name="name"
        placeholder="e.g. Freelance designers"
        required
      />
      <FieldArray name="questions">
        {({ fields }) => (
          <>
            {hasQuestions && (
              <ul className="interview-builder__questions-list">
                {fields.map((fieldName, index) => (
                  <Question
                    fieldIndex={index}
                    fieldName={fieldName}
                    fieldsLength={fields.length}
                    index={index}
                    interviewId={interviewId}
                    key={fieldName}
                    onChange={change}
                    onMove={fields.move}
                    onRemove={() => {
                      fields.remove(index);
                    }}
                    onSwap={(oldIndex, newIndex) => {
                      fields.swap(oldIndex, newIndex);
                    }}
                    organizationId={activeOrg.id}
                    question={questions[index]}
                  />
                ))}
              </ul>
            )}
            {!hasQuestions && (
              <div className="interview-builder__intro-message">
                Select any of the below elements to start building your customised template.
              </div>
            )}
            {submitError && <p className="text-danger">{submitError}</p>}
            <DocumentSelectField
              allowCountersigners={activeOrg.documents_with_countersigning_enabled}
              className="mb-3"
              showSelected
              sublabel={`Do you require invited team members to review and agree to any legal
                documents before obtaining access to the organization?`}
            />
            <div className="interview-builder__controls d-flex flex-nowrap">
              {TYPE_OPTIONS.map(({ icon, label, type }) => (
                <TDButton
                  block
                  key={type}
                  onClick={() => {
                    fields.insert(questionsLength, {
                      description: '',
                      id: questionsLength,
                      path: getCustomFieldPath(questionsLength),
                      payload: {},
                      type,
                    });
                  }}
                  variant={BS_STYLE.LINK}
                >
                  <span className={icon} />
                  {label}
                </TDButton>
              ))}
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

InterviewFormConfigure.propTypes = {
  match: routerMatchSpec.isRequired,
};

export default withRouter(InterviewFormConfigure);
