import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import CustomFieldRenderer from 'core/assets/js/components/CustomFieldRenderer.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';
import { orgTemplatesManageUrl } from 'settings/urls';
import { uploaderInterviewPath } from 'core/urls';
import { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import MultitextField from 'core/assets/js/components/FinalFormFields/MultitextField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import {
  BS_SIZE, BS_STYLE, ICON, MAX_UPLOAD_FILES, MIME_TYPES, USER_TYPE,
} from 'core/assets/js/constants';
import {
  ATTACHMENT_TYPE_OPTIONS,
  ATTACHMENT_TYPE_VALUES,
  ENTITY_TYPE_LABEL,
  FIELD_ENTITY_TYPE,
  TYPE,
  TYPE_LABEL,
  VISIBLE_TO_OPTIONS,
} from 'interviews/assets/js/constants';
import { Card } from 'react-bootstrap';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TemplateControlSelector from 'settings/assets/js/components/TemplateControlSelector.jsx';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { pluralize, scrollToFirstError } from 'core/assets/js/lib/utils';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import ButtonsContainer from 'core/assets/js/components/ButtonsContainer.jsx';
import { validateVisibility } from 'settings/assets/js/lib/utils';

const FORM_ID = 'template-builder';

const SortableCustomField = ({
  form, data, fields, initialValues, userCard, name, idx, entityType,
  showSearchableInfoModal, showReportableInfoModal, meta: { submitError },
}) => {
  const index = idx;
  const mimeTypes = Array.isArray(data.mimetypes) && !isEmpty(data.mimetypes)
    ? data.mimetypes
    : MIME_TYPES.DOCUMENTS;

  const selectedVisibleToOptions = Array.isArray(data?.payload?.visibleTo)
    ? VISIBLE_TO_OPTIONS.filter(opt => (data?.payload?.visibleTo || []).includes(opt.value))
    : VISIBLE_TO_OPTIONS;

  const { usages = 0 } = fields.value[index] || {};
  const handleFieldRemoved = () => fields.remove(index);
  const entityTypeLabelPlural = pluralize(ENTITY_TYPE_LABEL[entityType], usages).toLowerCase();
  const isAnsweredByUserTypeVisible = FIELD_ENTITY_TYPE.USER === entityType
    && data.type !== TYPE.TEXT_BLOB;

  return (
    <div key={name} className="interview-builder__question">
      <div className="d-flex align-items-center title-container mb-3">
        {idx > 0 && (
          <i
            className={`${ICON.ARROW_UP} change-order-up pr-3`}
            onClick={() => fields.swap(idx, idx - 1)}
          />
        )}
        {idx < fields.length - 1 && (
          <i
            className={`${ICON.ARROW_DOWN} change-order-down pr-3`}
            onClick={() => fields.swap(idx, idx + 1)}
          />
        )}
        <h4 className="interview-builder__question-title m-0 p-0">
          {`FIELD ${index + 1}: `}
          <span>{TYPE_LABEL[data.type]}</span>
        </h4>
        <ConfirmationButton
          buttonClass="interview-builder__question-remove ml-auto pr-0"
          buttonIcon={ICON.CROSS}
          buttonSize={BS_SIZE.XSMALL}
          buttonStyle={BS_STYLE.LINK}
          modalProps={{
            body: (
              <React.Fragment>
                {!!usages && (
                  <p>
                    {`This custom field is used in ${usages} ${entityTypeLabelPlural}. `}
                    {entityType === FIELD_ENTITY_TYPE.USER && (
                      `By deleting it, you will also delete the relevant information
                      from the user profiles and contacts that use it.`
                    )}
                    {entityType !== FIELD_ENTITY_TYPE.USER && (
                      `Deleting it does not affect the relevant information from the
                      ${entityTypeLabelPlural} that use it. However, it will not be available in any
                      new ${entityTypeLabelPlural} in the future.`
                    )}
                  </p>
                )}
                <p>
                  Are you sure you want to delete this custom field?
                </p>
              </React.Fragment>
            ),
            cancelLabel: 'Close',
            confirmLabel: 'Delete',
            confirmStyle: BS_STYLE.DANGER,
            heading: 'Delete Custom field',
            onConfirm: async () => handleFieldRemoved(),
          }}
        />
      </div>

      <div className="col-12 px-0">
        <TextInputField
          name={`${name}.label`}
          type={INPUT_TYPE.MARKDOWN_INPUT}
          component={TextInputField}
          placeholder="Label"
          validate={val => (!val ? 'Field is required' : null)}
          required
        />

        <TextInputField
          name={`${name}.description`}
          type={INPUT_TYPE.TEXT}
          placeholder="Optional description"
        />

        {data.type !== TYPE.TEXT_BLOB && (
          <RadioField
            name={`${name}.required`}
            label="Is this required?"
            options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
            required
          />
        )}

        { ![TYPE.TEXT_BLOB, TYPE.FILE].includes(data.type) && (
          <React.Fragment>
            <RadioField
              name={`${name}.isSearchable`}
              label="Is this searchable?"
              sublabel="Setting this field as searchable allows you to search via a dedicated filter, which performs an exact match search."
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            { /* Show info popup when user sets isSearchable to false */ }
            <TDFinalFormOnChange name={`${name}.isSearchable`}>
              {(value, previous) => {
                if (value !== previous && value === false) {
                  showSearchableInfoModal();
                }
              }}
            </TDFinalFormOnChange>
          </React.Fragment>
        )}

        {data.type === TYPE.TEXT_BLOB && (
          <TextAreaField
            name={`${name}.payload.text`}
            label="Text"
            required
            validate={val => (!val ? 'Field is required' : null)}
            mdEditorEnabled
          />
        )}

        {data.type === TYPE.TEXT && (
          <div>
            <RadioField
              name={`${name}.payload.isTextArea`}
              label="Use multiple lines?"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />
          </div>
        )}

        {data.type === TYPE.SELECT && (
          <div>
            <RadioField
              name={`${name}.payload.multiple`}
              label="Select multiple answers"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            <Field name={`${name}.payload.choicesAutoIncrement`} type="hidden" component="input" />

            <MultitextField
              asOptions
              inputType={INPUT_TYPE.MARKDOWN_INPUT}
              label="Choices"
              maxLength={255}
              name={`${name}.payload.choices`}
              onAutoIncrementUpdate={newAI => form.mutators.setChoicesAI(name, newAI)}
              required
            />

            <RadioField
              name={`${name}.payload.has_other`}
              component={RadioField}
              label="Has 'Other' option?"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />
          </div>
        )}

        {data.type === TYPE.FILE && (
          <React.Fragment>
            <CheckboxField
              name={`${name}.payload.attachment_types`}
              label="Allowed attachment types:"
              options={ATTACHMENT_TYPE_OPTIONS}
            />

            <RadioField
              name={`${name}.payload.multiple`}
              label="Upload multiple files"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            <FileUploaderField
              name={`${name}.payload.attachments_dump`}
              label="Optional file template"
              acceptFiles={mimeTypes}
              maxFiles={MAX_UPLOAD_FILES}
              path={uploaderInterviewPath(
                userCard.organization.id,
                initialValues.id,
              )}
            />
          </React.Fragment>
        )}

        {isAnsweredByUserTypeVisible && (
          <RadioField
            label="Who should be able to answer this question?"
            name={`${name}.answeredByUserType`}
            options={[
              { value: USER_TYPE.PROVIDER, text: 'Provider' },
              { value: USER_TYPE.MANAGER, text: 'Manager' },
            ]}
            required
            sublabel={(
              'If Provider is chosen, then this question will only be answerable by the Provider. '
              + 'If Manager is chosen, then only Managers will be able to answer this question.'
            )}
          />
        )}

        <div className="row">
          <div className="col-auto">
            <CheckboxField
              name={`${name}.payload.visibleTo`}
              label="Visible to"
              sublabel="Select the roles of the users who access access this custom field."
              options={VISIBLE_TO_OPTIONS}
              valueTransformer={item => item.value}
              selectedOptions={selectedVisibleToOptions}
            />

            { ![TYPE.TEXT_BLOB, TYPE.FILE].includes(data.type) && (
              <React.Fragment>
                { /* Show info popup when user sett isSearchable to false */ }
                <TDFinalFormOnChange name={`${name}.isReportable`}>
                  {(value, previous) => {
                    if (value !== previous && value === true) {
                      showReportableInfoModal();
                    }
                  }}
                </TDFinalFormOnChange>

                <RadioField
                  name={`${name}.isReportable`}
                  label="Is this reportable?"
                  sublabel="Setting this field as reportable grants permission to the system to include it in the contractor invoices."
                  options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
                  required
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      {submitError && submitError && submitError[index] && (
        <div className="has-error">
          <span className="help-block">{submitError[index]}</span>
        </div>
      )}
    </div>
  );
};

SortableCustomField.propTypes = {
  data: PropTypes.shape({
    mimetypes: PropTypes.arrayOf(PropTypes.string),
    payload: PropTypes.shape({
      visibleTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_TYPE))),
    }),
    type: PropTypes.oneOf(Object.values(TYPE)),
  }).isRequired,
  entityType: PropTypes.oneOf(Object.values(FIELD_ENTITY_TYPE)).isRequired,
  fields: PropTypes.shape({
    length: PropTypes.number,
    remove: PropTypes.func,
    swap: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  form: PropTypes.shape({ mutators: PropTypes.shape({ setChoicesAI: PropTypes.func }) }).isRequired,
  idx: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  meta: PropTypes.shape({ submitError: PropTypes.string }).isRequired,
  name: PropTypes.string.isRequired,
  showReportableInfoModal: PropTypes.func.isRequired,
  showSearchableInfoModal: PropTypes.func.isRequired,
  userCard: userCardSpec.isRequired,
};

const TemplateBuilderForm = ({
  history, userCard, templateType, onSubmit, initialValues, match,
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [searchableInfoModalOpen, setSearchableInfoModalOpen] = useState(false);
  const [reportableInfoModalOpen, setReportableInfoModalOpen] = useState(false);
  const showPreviewModal = () => setPreviewModalOpen(true);
  const showSearchableInfoModal = () => setSearchableInfoModalOpen(true);
  const showReportableInfoModal = () => setReportableInfoModalOpen(true);
  const { orgAlias } = match.params;

  const handleItemAdded = (item, fields) => (
    fields.push({
      ...item,
      path: `cf_${fields.length + 1}`,
      payload: {
        visibleTo: Object.values(USER_TYPE),
      },
    })
  );

  return (
    <Form
      onSubmit={async values => {
        let errors = null;
        (values.questions || []).forEach((value, index) => {
          let error = validateVisibility(value);
          if (
            !error
            && [TYPE.FILE, TYPE.MULTIFILE].includes(value.type)
            && !(value.payload.attachment_types || []).some(
              a => ATTACHMENT_TYPE_VALUES.includes(a.value),
            )
          ) {
            error = 'You must select at least one valid attachment type';
          }
          if (error) {
            if (!errors) {
              errors = { questions: {} };
            }
            errors.questions[index] = error;
          }
        });
        if (!errors) {
          errors = await onSubmit(values);
        }
        if (errors) {
          scrollToFirstError(
            FORM_ID,
            {
              getElementToScrollTo: errorDOM => {
                const questionContainer = errorDOM.closest('.interview-builder__question');
                return questionContainer || errorDOM;
              },
            },
          );
          return errors;
        }
        return null;
      }}
      mutators={{
        ...arrayMutators,
        setChoicesAI: (args, state, utils) => {
          utils.changeValue(state, `${args[0]}.payload.choicesAutoIncrement`, () => args[1]);
        },
      }}
      initialValues={{
        ...initialValues,
        field_entity_type: templateType,
      }}
      render={({
        initialValues: formInitialValues, form, handleSubmit, submitting,
      }) => (
        <form id={FORM_ID} onSubmit={handleSubmit} className="p-0">
          <Card className="template-builder">
            <Card.Body className="py-0">
              <FieldArray name="questions">
                {({ fields, meta }) => (
                  <div className="row">
                    <div className="col-12 p-0 template-control-selector-wrapper">
                      <TemplateControlSelector
                        onAdd={item => handleItemAdded(item, fields)}
                        templateType={templateType}
                      />
                    </div>

                    <div className="col py-4 border-left">
                      <Field name="field_entity_type" type="hidden" component="input" />
                      <div className="row">
                        <div className="col-12">
                          <TextInputField
                            name="name"
                            required
                            label="Template name"
                            placeholder="e.g Simple task"
                            validate={val => (!val ? 'Field is required' : null)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <RadioField
                            name="isMandatory"
                            label="Is this template mandatory?"
                            options={[
                              { value: true, text: 'Yes' },
                              { value: false, text: 'No' },
                            ]}
                            validate={val => (typeof val === 'undefined' ? 'Field is required' : null)}
                            required
                          />
                        </div>
                      </div>

                      <h3 className="heading-block mt-4 mb-4">Template fields</h3>

                      <div className="interview-builder__questions-list py-0">
                        {fields.map((name, index) => {
                          const data = fields.value && fields.value[index];
                          return (
                            <SortableCustomField
                              data={data}
                              entityType={templateType}
                              fields={fields}
                              form={form}
                              idx={index}
                              initialValues={formInitialValues}
                              key={name}
                              meta={meta}
                              name={name}
                              showReportableInfoModal={showReportableInfoModal}
                              showSearchableInfoModal={showSearchableInfoModal}
                              userCard={userCard}
                            />
                          );
                        })}
                      </div>

                      {fields.length === 0 && (
                        <div className="interview-builder__intro-message">
                          Select any of the elements in the controls list to start building
                          your customised template.
                        </div>
                      )}
                    </div>

                    <ModalSimple
                      open={previewModalOpen}
                      heading="Custom fields preview"
                      body={fields.map((field, index) => {
                        const data = fields.value && fields.value[index];
                        const key = `custom-field-${index}`;
                        return (
                          <CustomFieldRenderer
                            key={key}
                            field={data}
                          />
                        );
                      })}
                      onClose={() => setPreviewModalOpen(false)}
                      fullWidth
                    />

                    <ModalSimple
                      open={searchableInfoModalOpen}
                      heading={(
                        <React.Fragment>
                          <i className={`discreet mr-2 ${ICON.WARNING}`} />
                          Did you know?
                        </React.Fragment>
                      )}
                      body={(
                        <p>
                          By setting this custom field as non searchable, the dedicated filter
                          will not be available anymore in the relevant listings. You can always
                          set it back to searchable in the future if the need arises.
                        </p>
                      )}
                      onClose={() => setSearchableInfoModalOpen(false)}
                    />

                    <ModalSimple
                      open={reportableInfoModalOpen}
                      heading={(
                        <React.Fragment>
                          <i className={`discreet mr-2 ${ICON.WARNING}`} />
                          Did you know?
                        </React.Fragment>
                      )}
                      body={(
                        <>
                          <p>
                            By setting this field as reportable you grant permission to the system
                            to include it in the contractor invoices.
                          </p>
                          <p>
                            If this custom fields contains sensitive information that should not
                            be disclosed to your providers, you must not activate the reportable
                            option.
                          </p>
                        </>
                      )}
                      onClose={() => setReportableInfoModalOpen(false)}
                    />
                  </div>
                )}
              </FieldArray>
            </Card.Body>
          </Card>
          <ButtonsContainer
            className="mt-5 text-right"
            primaryButtons={[
              <TDButton
                data-testid="template-builder-form-save-template"
                disabled={submitting}
                key="save"
                label="Save template"
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />,
            ]}
            secondaryButtons={[
              <TDButton
                className="mb-4 mb-sm-0"
                key="cancel"
                label="Cancel"
                onClick={() => history.push(
                  orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.TEMPLATES),
                )}
                variant={BS_STYLE.DEFAULT}
              />,
              <TDButton
                key="preview"
                label="Preview"
                onClick={showPreviewModal}
                variant={BS_STYLE.DEFAULT}
              />,
            ]}
          />
        </form>
      )}
    />
  );
};

TemplateBuilderForm.propTypes = {
  templateType: PropTypes.number.isRequired,
  history: routerHistorySpec.isRequired,
  userCard: userCardSpec.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  match: routerMatchSpec.isRequired,
};

TemplateBuilderForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = state => ({
  userCard: selectActiveUserCard(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TemplateBuilderFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateBuilderForm);

export default withRouter(TemplateBuilderFormConnected);
