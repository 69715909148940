import arrayMutators from 'final-form-arrays';
import { debounce, isEmpty, omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import WizardSteps from 'core/assets/js/components/FinalFormFields/WizardSteps.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';

const DEBOUNCE_ON_CHANGES = 1000;

class Wizard extends React.Component {
  static Page({ children }) {
    return children;
  }

  constructor(props) {
    super(props);
    const { defaultPage, initialValues } = props;

    this.state = {
      page: defaultPage,
      values: initialValues,
    };

    this.saveWizardState = debounce(
      this.saveWizardState, DEBOUNCE_ON_CHANGES,
    );

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.validate = this.validate.bind(this);
    this.getActivePage = this.getActivePage.bind(this);
    this.getActivePages = this.getActivePages.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.saveWizardState = this.saveWizardState.bind(this);
    this.getPageNumber = this.getPageNumber.bind(this);
  }

  getActivePage(values) {
    const page = this.getPageNumber();
    return this.getActivePages(values)[page];
  }

  getActivePages(values) {
    const { children } = this.props;
    return React.Children.toArray(children).filter(child => (
      (
        child.type === Wizard.Page
        || [child.type.displayName, child.type.name].includes(Wizard.Page.name)
      )
      && (child?.props?.showStep ? child.props.showStep(values) : true)
    ));
  }

  getPageNumber() {
    const { pageNumber } = this.props;
    const { page } = this.state;
    return pageNumber !== null ? pageNumber : page;
  }

  async next(values) {
    const { onStepChange } = this.props;
    const page = this.getPageNumber();

    const activePages = this.getActivePages(values);
    const activePage = activePages[page];
    const onStepSubmission = activePage.props?.onStepSubmission;
    if (onStepSubmission) {
      const stepErrors = await onStepSubmission(values);
      if (!isEmpty(stepErrors)) {
        return stepErrors;
      }
    }

    this.setState({
      page: page + 1,
      values,
    });
    onStepChange(page + 1, values);
    return null;
  }

  previous() {
    const { onStepChange } = this.props;
    const isStepBack = true;
    const values = {};
    const page = this.getPageNumber();
    this.setState(state => ({
      page: state.page - 1,
    }));
    onStepChange(page - 1, values, isStepBack);
  }

  goToPage(page) {
    const { onStepChange } = this.props;
    this.setState(() => ({
      page,
    }));
    onStepChange(page, {}, false);
  }

  validate(values) {
    const activePage = this.getActivePage(values);
    return activePage.props.validate ? activePage.props.validate(values) : {};
  }

  async handleSubmit(initialValues, form) {
    const { secondarySubmit } = initialValues;
    let values = omit(initialValues, 'secondarySubmit');
    const { onSubmit, saveWizardStateOnAnyChanges, secondarySubmitAction } = this.props;
    const page = this.getPageNumber();
    const activePages = this.getActivePages(values);
    const isLastPage = page === activePages.length - 1;

    if (!saveWizardStateOnAnyChanges) {
      this.saveWizardState(values);
    }

    const activePage = this.getActivePage(values);
    if (activePage.props.beforePageChange) {
      const response = await activePage.props.beforePageChange(values, form);
      if (response) {
        if (response.values) {
          values = response.values;
        } else {
          return response;
        }
      }
    }

    const errors = this.validate(values);
    if (!isEmpty(errors)) {
      return errors;
    }

    if (isLastPage) {
      const submitFn = secondarySubmit && secondarySubmitAction ? secondarySubmitAction : onSubmit;
      return submitFn(values);
    }

    return this.next(values);
  }

  saveWizardState(values) {
    const { saveWizardState } = this.props;
    saveWizardState(values);
  }

  render() {
    const {
      alwaysShowBackCta,
      backStyle,
      cancelStyle,
      className,
      confirmStyle,
      extraContent,
      formId,
      includeArrayMutators,
      keepDirtyOnReinitialize,
      lastStepIsLoader,
      onCancel,
      resolveWrapperStepClass,
      saveWizardStateOnAnyChanges,
      secondarySubmitAction,
      secondarySubmitButtonTitle,
      secondarySubmitLoading,
      showButtons,
      showFormErrors,
      showSteps,
      submitBtnTitle,
      submittingBtnTitle,
      vertical,
      withShadow,
    } = this.props;
    const page = this.getPageNumber();
    const { values: initialValues } = this.state;

    const wrapperClasses = [`wizard-${vertical ? 'vertical' : 'horizontal'}`];
    if (className) {
      wrapperClasses.push(className);
    }

    return (
      <Form
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          clear: ([field], state, { changeValue }) => {
            changeValue(state, field, () => undefined);
          },
          ...(includeArrayMutators ? arrayMutators : {}),
        }}
      >
        {(props) => {
          const {
            form, form: { change, mutators }, handleSubmit, submitting,
            pristine, submitError,
          } = props;
          const values = omit(form.getState().values, 'secondarySubmit');
          const activePages = this.getActivePages(values);
          const activePage = activePages[page];
          const {
            props: {
              component: Component,
              componentProps,
              contentPrefix,
              ...passedProps
            },
          } = activePage;
          const {
            backBtnTitle,
            getSubmissionStatus,
            nextBtnSubmittingTitle = 'Loading...',
            nextBtnTitle,
            showBackButton = true,
            title: currentStepTitle,
            withStepContentContainer = true,
          } = passedProps;
          const isLastPage = page === activePages.length - 1;
          const steps = activePages.map(({ props: thisProps }, index) => ({
            ...pick(thisProps, 'nextBtnTitle', 'title'),
            active: index === page,
            completed: index < page,
            page: index + 1,
          }));

          if (saveWizardStateOnAnyChanges) {
            this.saveWizardState(values);
          }

          const submissionStatusDisabled = getSubmissionStatus
            ? getSubmissionStatus(values)
            : false;
          const isSubmissionDisabled = submitting || submissionStatusDisabled;

          const finalComponentProps = typeof componentProps === 'function'
            ? componentProps(values)
            : componentProps;

          const content = (
            <>
              {Component ? (
                <Component
                  formValues={values}
                  {...finalComponentProps}
                  {...passedProps}
                  mutators={mutators}
                  goToPage={this.goToPage}
                  pristine={pristine}
                  submitting={submitting}
                  updateFormField={change}
                />
              ) : activePage}
              {submitError && showFormErrors && <p className="text-danger">{submitError}</p>}
              {extraContent}
            </>
          );

          let nextButton = null;

          const submitButton = (
            <>
              {secondarySubmitAction && (
                <TDButton
                  disabled={secondarySubmitLoading}
                  id="wizard-button-secondary-submit"
                  onClick={() => {
                    form.change('secondarySubmit', true);
                  }}
                  type="submit"
                >
                  {secondarySubmitButtonTitle}
                </TDButton>
              )}
              <TDButton
                data-testid="wizard-button-submit"
                disabled={submitting || (vertical && !isLastPage)}
                id="wizard-button-submit"
                onClick={() => {
                  form.change('secondarySubmit', false);
                }}
                type="submit"
                variant={confirmStyle}
              >
                {submitting ? (submittingBtnTitle || submitBtnTitle) : submitBtnTitle}
              </TDButton>
            </>
          );

          if (isLastPage && !vertical && !lastStepIsLoader) {
            nextButton = submitButton;
          }

          if (!isLastPage) {
            let finalNextBtnTitle = 'Next';
            if (typeof nextBtnTitle === 'string') {
              finalNextBtnTitle = nextBtnTitle;
            } else if (typeof nextBtnTitle === 'function') {
              finalNextBtnTitle = nextBtnTitle(values);
            }
            nextButton = (
              <TDButton
                data-testid="wizard-button-next"
                disabled={isSubmissionDisabled}
                id="wizard-button-next"
                variant={confirmStyle}
                type="submit"
              >
                {submitting ? nextBtnSubmittingTitle : finalNextBtnTitle}
              </TDButton>
            );
          }

          const cancelButton = onCancel && (
            <TDButton
              data-testid="wizard-button-cancel"
              id="wizard-button-cancel"
              onClick={onCancel}
              variant={cancelStyle}
            >
              Cancel
            </TDButton>
          );

          let containerClass = `rounded${withShadow ? ' shadow-sm' : ''} p-4 bg-white`;

          const stepsRender = !vertical && showSteps && <WizardSteps steps={steps} />;

          const wrapperStepClass = resolveWrapperStepClass(activePage.props.step);
          if (wrapperStepClass && !wrapperClasses.includes(wrapperStepClass)) {
            wrapperClasses.push(wrapperStepClass);
          }

          if (vertical) {
            const beforeSteps = [];
            const afterSteps = [];

            activePages.forEach((thisPage, index) => {
              if (index === page) {
                return;
              }
              (index < page ? beforeSteps : afterSteps).push(thisPage);
            });

            containerClass += ' mb-3';

            return (
              <div className={wrapperClasses.join(' ')}>
                {stepsRender}
                {contentPrefix}
                <form id={formId} onSubmit={handleSubmit}>
                  {beforeSteps.map((step, index) => (
                    <div className={containerClass} key={step.props.title}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="wizard-vertical-step__num completed">
                            <i className={ICON.CHECKMARK} />
                          </div>
                          <b>{step.props.title}</b>
                        </div>
                        <span
                          className="imitate-link"
                          onClick={() => this.goToPage(index)}
                        >
                          Edit
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className={containerClass}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <div className="wizard-vertical-step__num active">{page + 1}</div>
                        <b>{currentStepTitle}</b>
                      </div>
                    </div>
                    {content}
                    <div className="d-flex justify-content-end mt-5">{nextButton}</div>
                  </div>
                  {afterSteps.map((step, index) => (
                    <div
                      className={`${containerClass} wizard-vertical-step__disabled`}
                      key={step.props.title}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="wizard-vertical-step__num">{page + 2 + index}</div>
                          <b>{step.props.title}</b>
                        </div>
                        <span>Complete previous step</span>
                      </div>
                    </div>
                  ))}
                  <div className="wizard-vertical__buttons d-flex align-items-center justify-content-between py-3 px-5 shadow-sm">
                    {cancelButton}
                    <div>{submitButton}</div>
                  </div>
                </form>
              </div>
            );
          }

          return (
            <div className={wrapperClasses.join(' ')}>
              {stepsRender}
              {contentPrefix}

              <form id={formId} onSubmit={handleSubmit}>
                {withStepContentContainer && <div className={containerClass}>{content}</div>}
                {!withStepContentContainer && content}

                { showButtons && (
                  <div className="wizard-buttons pr-4 pl-4 row mb-5">
                    <div className="col-3 mt-4 px-0 d-flex">
                      {cancelButton}
                    </div>

                    <div className="col-9 mt-4 text-right px-0 d-flex justify-content-end ">
                      {((!(lastStepIsLoader && isLastPage) && page !== 0) || alwaysShowBackCta)
                      && showBackButton
                      && (
                        <TDButton
                          data-testid="wizard-button-back"
                          disabled={page === 0}
                          id="wizard-button-back"
                          onClick={this.previous}
                          variant={backStyle}
                        >
                          {backBtnTitle || 'Back'}
                        </TDButton>
                      )}

                      {nextButton}
                    </div>
                  </div>
                )}
              </form>
            </div>
          );
        }}
      </Form>
    );
  }
}

Wizard.propTypes = {
  alwaysShowBackCta: PropTypes.bool,
  backStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  cancelStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  confirmStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  defaultPage: PropTypes.number,
  extraContent: PropTypes.element,
  formId: PropTypes.string,
  includeArrayMutators: PropTypes.bool,
  initialValues: PropTypes.object,
  keepDirtyOnReinitialize: PropTypes.bool,
  lastStepIsLoader: PropTypes.bool,
  onCancel: PropTypes.func,
  onParentInform: PropTypes.func,
  onStepChange: PropTypes.func,
  onSubmit: PropTypes.func,
  pageNumber: PropTypes.number,
  resolveWrapperStepClass: PropTypes.func,
  saveWizardState: PropTypes.func,
  saveWizardStateOnAnyChanges: PropTypes.bool,
  secondarySubmitAction: PropTypes.func,
  secondarySubmitButtonTitle: PropTypes.string,
  secondarySubmitLoading: PropTypes.bool,
  showButtons: PropTypes.bool,
  showFormErrors: PropTypes.bool,
  showSteps: PropTypes.bool,
  submitBtnTitle: PropTypes.string,
  submittingBtnTitle: PropTypes.string,
  vertical: PropTypes.bool,
  withShadow: PropTypes.bool,
};

Wizard.defaultProps = {
  alwaysShowBackCta: false,
  backStyle: BS_STYLE.DEFAULT,
  cancelStyle: BS_STYLE.DEFAULT,
  className: null,
  confirmStyle: BS_STYLE.PRIMARY,
  defaultPage: 0,
  extraContent: null,
  formId: null,
  includeArrayMutators: false,
  initialValues: {},
  keepDirtyOnReinitialize: false,
  lastStepIsLoader: false,
  onCancel: null,
  pageNumber: null,
  onParentInform: () => {},
  onStepChange: () => {},
  onSubmit: () => {},
  resolveWrapperStepClass: step => step,
  saveWizardState: () => {},
  saveWizardStateOnAnyChanges: false,
  secondarySubmitAction: null,
  secondarySubmitButtonTitle: null,
  secondarySubmitLoading: false,
  showButtons: true,
  showFormErrors: true,
  showSteps: true,
  submitBtnTitle: 'Submit',
  submittingBtnTitle: null,
  vertical: false,
  withShadow: true,
};

export default Wizard;
